<template>
  <div>
    <PageHeader :title="pageTitle" :items="breadcrumb" />
    <b-card>
      <b-form @submit.prevent="salvarBandeira">
        <b-row>
          <b-col md="4" class="mb-3">
            <b-form-group label="Cor da Bandeira" label-for="corBandeira">
              <b-form-input
                id="corBandeira"
                v-model="bandeira.corBandeira"
                type="text"
              />
            </b-form-group>
            <div class="form-group">
              <label for="mesRef">Mês Referência</label>
              <input
                type="text"
                class="form-control"
                v-model="bandeira.mesRef"
                v-mask="'##/##/####'"
              />
            </div>
          </b-col>
          <b-col md="4" class="mb-3">
            <b-form-group label="Valor (R$/MWh)" label-for="valorBandeira">
              <b-form-input
                id="valorBandeira"
                v-model="bandeira.valorBandeira"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <b-button type="submit" variant="success"> Salvar </b-button>
          </b-col>
          <b-col md="6" class="text-right">
            <b-button @click="removerBandeira" variant="danger">
              Remover
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import PageHeader from '@/components/page-header';
import { VueMaskDirective } from 'v-mask';

export default {
  directives: {
    mask: VueMaskDirective
  },
  data() {
    return {
      bandeira: {
        id: null,
        corBandeira: '',
        mesRef: '',
        valorBandeira: null
      },
      pageTitle: 'Editar Bandeira Tarifária',
      breadcrumb: [
        {
          text: 'Bandeiras Tarifárias'
        },
        {
          text: 'Editar Bandeira Tarifária',
          active: true
        }
      ]
    };
  },
  methods: {
    salvarBandeira() {
      // Lógica para salvar as mudanças da bandeira tarifária
      // Navegar para a página de listagem após salvar
      this.$router.push('/bandeiras-tarifarias');
    },
    removerBandeira() {
      // Lógica para remover a bandeira tarifária
      // Navegar para a página de listagem após remover
      this.$router.push('/bandeiras-tarifarias');
    },
    formatDate(dateString) {
      if (!dateString) return '';
      const [year, month, day] = dateString.split(/[-/]/);
      return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`;
    }
  },
  created() {
    const data = this.$route.query.bandeira;
    if (data != null) {
      try {
        const parsedData = JSON.parse(data);
        this.bandeira.corBandeira = parsedData.corBandeira;
        this.bandeira.mesRef = this.formatDate(parsedData.mesRef);
        this.bandeira.valorBandeira = parsedData.valorBandeira;
      } catch (error) {
        console.error(error);
      }
    }
  },
  components: {
    PageHeader
  }
};
</script>
